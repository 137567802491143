<template>
<v-ons-page class="error">
	<common-header
		type="allow"
		@click="$router.back()"
		v-show="!isWebview"
	>
		<h1 class="common-header-title__heading">よくある質問</h1>
	</common-header>
	<div class="question-box">
		<section class="question-box--list">
			<!-- NOTE: Android Webview用 -->
			<dl v-if="isWebview">
				<dt @click="clickAddClass">このアプリ（サイト）は何ができるのですか？</dt>
				<dd>
					<p>気圧の変動を予測して、頭痛の原因把握・予測・予防ができるアプリです。</p>
				</dd>
				<dt @click="clickAddClass">このアプリ（サイト）はどのように使えばいいのですか？</dt>
				<dd>
					<p>「気圧グラフ」と「頭痛ノート」を使って、気圧の変動とお客さまの頭痛発生の傾向を把握し、体調管理にお役立てください。</p>
				</dd>
				<dt @click="clickAddClass">何日先までの情報がわかりますか？</dt>
				<dd>
					<p>6日先までの気圧変化と天気、気温予報が確認できます。</p>
				</dd>
				<dt @click="clickAddClass">記録は何日間分、付けることができますか？</dt>
				<dd>
					<p>記録期間に制限はありません。</p>
				</dd>
				<dt @click="clickAddClass">プロフィール設定をしないと記録できないのですか？</dt>
				<dd>
					<p>プロフィール登録を設定しないと記録を付けることができません。頭痛の予報精度を向上させてアプリを改良するために利用いたします。</p>
				</dd>
				<dt @click="clickAddClass">記録をまとめて見ることはできますか？</dt>
				<dd>
					<p>「痛みノート」で記録結果を一覧で確認することができます。<br>ホーム画面下部の「痛みノート」からリストとカレンダーをご確認ください。</p>
				</dd>
				<dt @click="clickAddClass">アイコンの表示位置が違うのは何故ですか？</dt>
				<dd>
					<p>記録は1時間毎に付けることができます。スペースの問題でアイコンが横に並ぶと見にくくなるため、アイコンを上下にずらして表示しています。</p>
				</dd>
				<dt @click="clickAddClass">気圧低下アラートの表示はどのようなものですか？</dt>
				<dd>
					<p>1日の気圧変化の特徴を考慮して、1日を4つの時間帯（3時～9時、9時～15時、15時～21時、21時～翌3時）に分けています。この4つの時間帯毎に気圧低下量の年間平均値を算出し、それを基準に4段階で表示しています。時間帯によって表示のされ方が違っているのは、基準となる値が時間帯によって異なることによります。</p>
				</dd>
				<dt @click="clickAddClass">気圧グラフのアラート表示と自分の体調が合いません。</dt>
				<dd>
					<p>当アプリは気圧低下を主にお知らせしていますが、人によっては気圧の上昇に影響を受ける方や、早め・遅めに影響を受ける方もいらっしゃるとの報告を伺っています。</p>
				</dd>
				<dt @click="clickAddClass">全国マップとはどのようなものですか？</dt>
				<dd>
					<p>全国主要都市の気圧・天気予報・気温・風向・湿度が確認できます。<br>ホーム画面下部の「全国マップ」からご確認ください。</p>
				</dd>
				<dt @click="clickAddClass">全国マップの風向と湿度はどのような予報ですか？</dt>
				<dd>
					<p>風向は1日の中で最も強いと予想される風速とその風向です。<br>湿度は1日の中で最も低いと予想される湿度です。</p>
				</dd>
				<dt @click="clickAddClass">過去のグラフの一部が表示されないことがあります。</dt>
				<dd>
					<p>観測機器の障害等により気圧のデータが観測できなかった場合はその部分のグラフが表示されません。</p>
				</dd>
				<dt @click="clickAddClass">登録できる地点は１地点のみですか？</dt>
				<dd>
					<p>はい、1地点のみです。</p>
				</dd>
				<dt @click="clickAddClass">地点変更はどのような手順でできますか？</dt>
				<dd>
					<p>画面左上のメニュー⇒地点登録⇒都道府県⇒市区町村を選び、右上の「はい」を押せば選択できます。</p>
				</dd>
				<dt @click="clickAddClass">気圧グラフの現在時刻に戻る方法はありますか？</dt>
				<dd>
					<p>気圧グラフ画面 中央上部の時刻をタップする事で現在時刻に戻る事ができます。</p>
				</dd>
				<dt @click="clickAddClass">プッシュ通知が届きません。</dt>
				<dd>
					<p>端末の設定より通知設定がONになっているかご確認ください。<br>また、電波が良くない場合やキャリア側の要因等により届かない場合があります。</p>
				</dd>
				<dt @click="clickAddClass">どのくらい前の記録まで確認できますか？</dt>
				<dd>
					<p>2016年5月1日以降に記録をしたものから全て確認することができます。</p>
				</dd>
				<dt @click="clickAddClass">カレンダーの日毎の気圧低下アラートはどのような基準で表示されていますか？</dt>
				<dd>
					<p>登録地点における1日(03時～翌日3時)の中で最も気圧低下が大きかったアラートを表示しています。</p>
				</dd>
				<dt @click="clickAddClass">痛みノートのレポートの「痛み回数」とは何ですか？</dt>
				<dd>
					<p>3種類の痛みを押したそれぞれの回数を合計に対する割合を棒グラフで示したものです。</p>
				</dd>
				<dt @click="clickAddClass">痛みノートのレポートの「痛み回数」の「円グラフ」は何ですか？</dt>
				<dd>
					<p>痛み記録をした時間帯の気圧が下降していた場合と気圧が上昇していた場合の比率を円グラフで示したものです。</p>
				</dd>
				<dt @click="clickAddClass">みんなの痛みナウとは何ですか？</dt>
				<dd>
					<p>自分以外に気圧や天気によって体調を崩している人がどの程度いるのかがわかる機能です。<br>1時間単位で過去3日分を表示します。青色の縦棒グラフは独自の指数を用いて表示し、横棒グラフは痛みのレベルの割合を表示しています。</p>
				</dd>
				<dt @click="clickAddClass">気圧グラフに表示されている青色の縦棒グラフは何を表していますか？</dt>
				<dd>
					<p>登録地点が属する都道府県ごとにその時刻に痛み記録をした人数を元に青色の縦棒グラフで表示しています。縦棒が長いほどその地域で痛み記録のうち痛いを押した人が多くなります。なお、痛みの記録を付けた人がいない場合は表示されません。</p>
				</dd>
				<dt @click="clickAddClass">日時が表示されているエリアに表示される横棒グラフは何を表していますか？</dt>
				<dd>
					<p>登録地点が属する都道府県ごとにその時刻に記録された痛み記録のレベルごとの割合を横棒グラフで表示しています。グラフの色は痛みのレベルと同じになっています。</p>
				</dd>
				<dt @click="clickAddClass">日時が表示されているエリアに横棒グラフが表示されない時間がありますが、どうしてですか？</dt>
				<dd>
					<p>登録地点が属する都道府県で痛みの記録を付けた人がいない場合は横棒グラフは表示されません。</p>
				</dd>
				<dt @click="clickAddClass">横棒グラフが表示されて日時が確認できないのですが、日時の表示に変更できないのでしょうか。</dt>
				<dd>
					<p>横棒グラフを横スクロールすると日時表示に変更することができます。</p>
				</dd>
				<dt @click="clickAddClass">過去の痛みナウ（青色の縦棒グラフ、横棒グラフ）を確認することはできないのでしょうか？</dt>
				<dd>
					<p>確認できるのは3日前までとなっています。</p>
				</dd>
			</dl>

			<!-- NOTE: スゴ得iOS用 -->
			<dl v-else>
				<dt @click="clickAddClass">このサービスは何ができるのですか？</dt>
				<dd>
					<p>気圧の変動を予測して、頭痛の原因把握・予測・予防ができるサービスです。</p>
				</dd>
				<dt @click="clickAddClass">このサービスはどのように使えばいいのですか？</dt>
				<dd>
					<p>「気圧グラフ」と「頭痛ノート」を使って、気圧の変動とお客さまの頭痛発生の傾向を把握し、体調管理にお役立てください。</p>
				</dd>
				<dt @click="clickAddClass">画面の説明ページはありますか？</dt>
				<dd>
					<p>メイン画面で表示している内容については下記をご参照ください。</p>
					<p><span class="question_sprite sprite-tutorial_weathergraph" /></p>
					<p><span class="question_sprite sprite-tutorial_painnote" /></p>
				</dd>
				<dt @click="clickAddClass">何日先までの情報がわかりますか？</dt>
				<dd>
					<p>6日先までの気圧変化と天気、気温予報が確認できます。</p>
				</dd>
				<dt @click="clickAddClass">記録は何日間分、付けることができますか？</dt>
				<dd>
					<p>記録期間に制限はありません。</p>
				</dd>
				<dt @click="clickAddClass">プロフィール設定をしないと記録できないのですか？</dt>
				<dd>
					<p>プロフィール登録を設定しないと記録を付けることができません。<br>頭痛の予報精度を向上させてサービスを改良するために利用いたします。個人情報は取得しませんのでご安心ください。<br><span @click="clickDataHandringLink">※データの取り扱いについて</span></p>
				</dd>
				<dt @click="clickAddClass">記録をまとめて見ることはできますか？</dt>
				<dd>
					<p>「痛みノート」で記録結果を一覧で確認することができます。<br>画面左上のメニューボタン⇒痛みノート<br>リストとカレンダーで確認できます。</p>
				</dd>
				<dt @click="clickAddClass">アイコンの表示位置が違うのは何故ですか？</dt>
				<dd>
					<p>記録は1時間毎に付けることができます。スペースの問題でアイコンが横に並ぶと見にくくなるため、アイコンを上下にずらして表示しています。</p>
				</dd>
				<dt @click="clickAddClass">気圧低下アラートの表示はどのようなものですか？</dt>
				<dd>
					<p>1日の気圧変化の特徴を考慮して、1日を4つの時間帯（3時～9時、9時～15時、15時～21時、21時～翌3時）に分けています。<br>この4つの時間帯毎に気圧低下量の年間平均値を算出し、それを基準に4段階で表示しています。<br>時間帯によって表示のされ方が違っているのは、基準となる値が時間帯によって異なることによります。<br><span class="question_sprite sprite-low_pressure_alert" /></p>
				</dd>
				<dt @click="clickAddClass">気圧グラフのアラート表示と自分の体調が合いません。</dt>
				<dd>
					<p>当サービスは気圧低下を主にお知らせしていますが、人によっては気圧の上昇に影響を受ける方や、早め・遅めに影響を受ける方もいらっしゃるとの報告を伺っています。 <br>医療機関の受診もご検討ください。</p>
				</dd>
				<dt @click="clickAddClass">全国マップとはどのようなものですか？</dt>
				<dd>
					<p>全国主要都市の気圧・天気予報が確認できます。<br>画面左上のメニュー⇒全国マップ</p>
				</dd>
				<dt @click="clickAddClass">全国マップの風向と湿度はどのような予報ですか？</dt>
				<dd>
					<p>風向は1日の中で最も強いと予想される風速とその風向です。<br>湿度は1日の中で最も低いと予想される湿度です。</p>
				</dd>
				<dt @click="clickAddClass">過去のグラフの一部が表示されないことがあります。</dt>
				<dd>
					<p>観測機器の障害等により気圧のデータが観測できなかった場合はその部分のグラフが表示されません。</p>
				</dd>
				<dt @click="clickAddClass">登録できる地点は１地点のみですか？</dt>
				<dd>
					<p>はい、1地点のみです。</p>
				</dd>
				<dt @click="clickAddClass">地点変更はどのような手順でできますか？</dt>
				<dd>
					<p>画面左上のメニュー⇒地点登録⇒都道府県⇒市区町村を選択すると、地点変更ができます。</p>
				</dd>
				<dt @click="clickAddClass">気圧グラフをシェアする際に登録地点名を消せませんか？</dt>
				<dd>
					<p>地点によって気圧グラフが異なってくるため、登録地点名を明記しています。<br>予報を正確にお伝えするためにもご理解をいただけますと幸いです。</p>
				</dd>
				<dt @click="clickAddClass">気圧グラフの現在時刻に戻る方法はありますか？</dt>
				<dd>
					<p>気圧グラフ画面 中央上部の時刻をタップする事で現在時刻に戻る事ができます。</p>
				</dd>
				<dt @click="clickAddClass">どのくらい前の記録まで確認できますか？</dt>
				<dd>
					<p>2016年5月1日以降に記録をしたものから全て確認することができます。</p>
				</dd>
				<dt @click="clickAddClass">カレンダーの日毎の気圧低下アラートはどのような基準で表示されていますか？</dt>
				<dd>
					<p>登録地点における1日(03時～翌日3時)の中で最も気圧低下が大きかったアラートを表示しています。</p>
				</dd>
			</dl>
		</section>
	</div>
</v-ons-page>
</template>

<script>

// Vuex
import { mapGetters } from 'vuex'

// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'FrequentlyAskedQuestions',
	components: {
		CommonHeader
	},
	data () {
		return {
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isWebview'])
	},
	mounted () {
		// webviewの場合
		if (this.isWebview) {
			// コンテナに.is-webview（margin-topを消すclass）を付与する
			const domContainer = document.getElementById('js_container')
			if (domContainer) domContainer.classList.add('is-webview')

			// コンテンツの高さをemitする。
			const containerHeight = window.innerHeight
			this.$emit('containerHeight', containerHeight)
		} else {
			// コンテンツの高さをemitする。
			const containerHeight = window.innerHeight - cmnConst.HEADER_HEIGHT - cmnConst.SUGOTOKU_HEADER_HEIGHT - cmnConst.SUGOTOKU_FOOTER_HEIGHT // NOTE: 画面縦幅 - ヘッダー - スゴ得ヘッダー・フッター
			this.$emit('containerHeight', containerHeight)
		}
	},
	beforeDestroy () {
	},
	methods: {
		// NOTE: 開閉処理
		clickAddClass (e) {
			if (e.currentTarget.classList.contains('open')) {
				e.currentTarget.classList.remove('open')
			} else {
				e.currentTarget.classList.add('open')
			}
		},

		/**
		 * データ取り扱いリンク
		 */
		clickDataHandringLink () {
			this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=dataHandling` } })
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

.question-box {
	width: 100%;
	height: 100%;
	overflow: auto;
	background: $background-primary;
	h1 {
		text-align: center;
		font-size: $font-size-20;
	}
	&--list {
		width: 90%;
		margin: 0 auto $spacing-20*5;
		dl {
			dt {
				position: relative;
				font-size: $font-size-16;
				font-weight: bold;
				line-height: $line-height;
				padding-left: $spacing-20;
				margin-top: $spacing-20;
				text-decoration: underline;
			}
			dt:before {
				content: 'Q.';
				font-size: $font-size-18;
				font-weight: bold;
				color: $text-q;
				line-height: $line-height;
				position: absolute;
				top: 0;
				left: 0;
			}
			dt.open + dd {
				display: block;
			}
			dd {
				display: none;
				margin-inline-start: 0;
				position: relative;
				font-size: $font-size-16;
				line-height: $line-height;
				padding-left: $spacing-20;
				margin-top: $spacing-10;
				> p > span {
					width: 100%;
					border-bottom: solid 1px;
				}
			}
			dd:before {
				content: 'A.';
				font-size: $font-size-18;
				font-weight: bold;
				color: $text-a;
				line-height: $line-height;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

// スプライトシート
.question_sprite {
	background-image: url('../assets/img/sprite_question.png');
	background-repeat: no-repeat;
	display: block;
}
.question_sprite.sprite-low_pressure_alert {
	width: 480px;
	height: 150px;
	background-position: 0 0;
	transform: scale(1);
	zoom: 0.5;
	margin: 0 auto;
	border-bottom: none;
}
.question_sprite.sprite-tutorial_painnote {
	width: 678px;
	height: 1265px;
	background-position: 0 -150px;
	transform: scale(1);
	zoom: 0.4;
	margin: 0 auto;
	padding: 0;
	border-bottom: none;
}
.question_sprite.sprite-tutorial_weathergraph {
	width: 678px;
	height: 1265px;
	background-position: 0 -1415px;
	transform: scale(1);
	zoom: 0.4;
	margin: 0 auto;
	padding: 0;
	border-bottom: none;
}

</style>
